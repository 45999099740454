<template>
    <!-- See: `getFullVh()` -->
    <div style="overflow: hidden; height: 0">
        <div id="measure-vh" style="position: fixed; height: 100vh"></div>
    </div>
    <!-- showPayoutBreakdown -->
    <div id="topdiv" class="modal-overlay" style="width: 100%; ">
        <div class="modal" style="outline:0.0em solid blue; display: table-cell; padding-top: 0vh; padding-bottom: 0vh; " :style="{ 'height': 98 * this.vhMult + 'vh'}"> 

        <table width=100% border=0 style="padding-left: 2.5vh; padding-right: 2.5vh; ">
            <tr>
                <td>
                    <table width="100%" border=0 style="height: 6vh">
                        <tr>
                            <td width="30%">
                                <div class=divl><button class="text-only-button cancel" @click="this.goBack()" style="display: block;" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }"><img class="tulogo" valign=middle style="height: 1.8vh; padding-bottom: 0.3vh" alt="back" src="../assets/chevron.png"> Back</button></div>
                            </td>
                            <td width="40%" ><span style="display: block;" :style="{ 'font-size': fontSizeNormalInPXToUse * 1.2 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 1.2 + 'px', }">Payout Breakdown</span>
                            </td>
                            <td width="30%">
                            <div class=divr>
                            
                                <button @click="this.doShowExport()" class="text-only-button cancel divr"  :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }" style="display: none;">Export</button>
                            </div>
                            </td>

                        </tr>
                    </table>
                    
                </td>
            </tr>
            <tr>
                <td >
                    <!-- <div style="overflow:auto;" :style="{ 'height': 80 * this.vhMult + 'vh'}"> -->
                        <table class="wrapper-todiv"  align=center cellpadding=0 border=0 width=100%>
            
                            <tr>

                                <td align=left width=30% class="lt-blue divl" style="height: 1vh; letter-spacing: -0.41px" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                                    <span style="color:#718096; padding-right:2vh" >Tip date</span>
                                </td>
                                
                                <td align=left width=40%  :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                                    <span style="color:#718096; padding-right:2vh" >Tipper</span>
                                </td>
                                <td align=right width=20% class="lt-blue divr" style="height: 1vh; letter-spacing: -0.41px" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                                    <span style="color:#718096; padding-right:0vh" >Amount</span>
                                </td>
                                <td align=right width=10% >
                                    
                                </td>
                                
                            </tr>
                    
                        </table>
                    <!-- </div>               -->
                </td>
            </tr>
            <tr>
                <td >
                    <div style="overflow:auto;" :style="{ 'height': 80 * this.vhMult + 'vh'}">
                    <div :key="payment.objectId" v-for="payment in paymentsArray" >
                        <PayoutBreakdownRecord :paymentObjectId=payment.objectId :paymentTransactionId=payment.paymentObjectId  :paymentOperator=payment.paymentOperator :paymentAmountReceived=payment.amount :paymentDate=payment.date :paymentTipperName=payment.tipperName @payment-selected="paymentSelected"/>
                    </div>   
                    </div>              
                </td>
            </tr>
        </table>       
      </div>
    </div>
</template>

<script>

const Parse = require('parse/node');

import PayoutBreakdownRecord from './PayoutBreakdownRecord.vue'
import shared from '../shared.js';

export default {
    name: 'PayoutBreakdownScreen',
    inject: ['globalPushForwardInterval', 'devEnv', 'appId', 'appJSKey'] ,
    props:  {
        userData: shared.userDataStruct(),
        payoutId: String,
    },
    components: {
        PayoutBreakdownRecord,
    },
    data () {
        return {
            fontSizeNormal: 2.5, // vh
            fontSizeNormalInPXToUse: "",
            fontSizeNormalLineHeight: 3.3,// vh
            fontSizeNormalInPXLineHeightToUse: "",
            fontSizeRecord: 2.5, // vh
            fontSizeRecordInPXToUse: "",
            fontSizeRecordLineHeight: 2.7,// vh
            fontSizeRecordInPXLineHeightToUse: "",
            userObjectId: undefined,
            paymentsArray: [
                 {
                objectId: 1,
                paymentAmountReceived: undefined,
              },
              {
                objectId: 2,
                paymentAmountReceived: undefined,
              },
              {
                objectId: 3,
                paymentAmountReceived: undefined,
              },
              {
                objectId: 4,
                paymentAmountReceived: undefined,
              },
              {
                objectId: 5,
                paymentAmountReceived: undefined,
              },
               {
                objectId: 6,
                paymentAmountReceived: undefined,
              },
               {
                objectId: 7,
                paymentAmountReceived: undefined,
              },
               {
                objectId: 8,
                paymentAmountReceived: undefined,
              },
               {
                objectId: 9,
                paymentAmountReceived: undefined,
              },
               {
                objectId: 10,
                paymentAmountReceived: undefined,
              },
               {
                objectId: 11,
                paymentAmountReceived: undefined,
              },
               {
                objectId: 12,
                paymentAmountReceived: undefined,
              },
               {
                objectId: 13,
                paymentAmountReceived: undefined,
              },
               {
                objectId: 14,
                paymentAmountReceived: undefined,
              },
               {
                objectId: 15,
                paymentAmountReceived: undefined,
              },
               {
                objectId: 16,
                paymentAmountReceived: undefined,
              },
               {
                objectId: 17,
                paymentAmountReceived: undefined,
              },
            ],
            vhMult: 1,
            availablePaymentsArray: [],
            mostRecentPaymentsNotYetInTransitArray: [],
            forWeeklyMonthlyAvailableNotYetInTransitArray: [],
        }
    },
    methods: {
        doFontSizeComputed () {
            this.fontSizeNormalInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormal, 16);
            this.fontSizeNormalInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormalLineHeight, 20);
            this.fontSizeRecordInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeRecord, 16);
            this.fontSizeRecordInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeRecordLineHeight, 17);
        },
        paymentSelected(paymentObjectId){
            // console.log("transaction object ID selected: " + paymentObjectId);

            if (paymentObjectId === "n/a"){
                return;
            }

            this.userData.selectedPaymentObjectId = paymentObjectId;
            this.userData.fromScreen = "showPayoutBreakdown";
            this.userData.nextScreen = "showTipInfoRecipient";
            // Parse.Cloud.run("sendEmail", {
            //     toEmail: "appnotif@thanku.app", 
            //     subject: "ALERT! Recipient " + this.userData.displayname + " viewed tip info from Payouts Breakdown",
            //     body: "",
            // });
            this.$emit("next-action", this.userData);

            // this.$emit("next-action", "showTipInfoRecipient", paymentObjectId);
        },
        goBack(){

            if (this.userData.fromScreen !== undefined){
                if (this.userData.nextScreen === "showPayoutBreakdown"){
                    this.userData.nextScreen = "showPayouts";
                } else {
                    this.userData.nextScreen = this.userData.fromScreen;
                }
                
            } else {
                this.userData.nextScreen = "showPayouts";
            }
            // console.log("this.userData::: " + JSON.stringify(this.userData, null, 2));
            this.$emit("next-action", this.userData);
            // this.$emit("next-action", "backFromPayoutBreakdown");
        },
        doShowExport(){
            // console.log("do show export");
        },
        async initpayoutbreakdown() {

            // console.log("PAYOUT BREAKDOWN this surely is my id: " + this.userData.userObjectId);
            // console.log("AND this surely is my payoutId: " + this.payoutId);

            var params = {
                userObjectId: this.userData.userObjectId,
                payoutId: this.payoutId,
            }

            // console.log("this.userData: " + JSON.stringify(this.userData, null, 2));
            
            let runningTotal = 0;
            let currencySymbol = "";

            try {
               
                if (this.payoutId.indexOf("po_dummy") > -1){

                    

                    // console.log("this.payoutId: " + this.payoutId);

                    this.$nextTick(() => { 
                    // the key is we CANNOT update the array before the above for has re-rendered otherwise we get into an endless loop

                        console.log("this.paymentsArray DUMMY: " + JSON.stringify(this.payoutId, null, 2));

                        this.availablePaymentsArray = this.userData.paymentsArray.filter(item => item.inAvailableBalanceNotYetPaid === true);

                        this.mostRecentPaymentsNotYetInTransitArray = this.userData.paymentsArray.filter(item => item.mostRecentPaymentsNotYetInTransit === true);
                        this.paymentsArray = this.mostRecentPaymentsNotYetInTransitArray.filter(item =>  item.payoutId === this.payoutId);


                        this.paymentsArray = this.paymentsArray.concat(this.availablePaymentsArray);

                        if (this.userData.stripeBalancesObject.availableNowBalance !== undefined && this.userData.stripeBalancesObject.availableNowBalanceNumber > 0 && (this.userData.recipientPayoutsFrequency === "monthly" || this.userData.recipientPayoutsFrequency === "weekly")){
                            // there will be payments which are in Stripe's 'Total Balance' and 'Future Payouts' which are not in transit because of monthly or weekly payouts, they are being picked up in AvailableBalance. to pick these up we need to filter for pendingPayment is true and most RecentPaymentsNotYetInTransit = false, not sure why the latter but it is the case

                            this.forWeeklyMonthlyAvailableNotYetInTransitArray = this.userData.paymentsArray.filter(item => (item.mostRecentPaymentsNotYetInTransit === false && item.pendingPayment === true));

                            this.paymentsArray = this.paymentsArray.concat(this.forWeeklyMonthlyAvailableNotYetInTransitArray);
                        }


                        for (const newPayment of this.paymentsArray) {
                            // console.log("payout payment id: " + newPayment.paymentObjectId);
                            console.log("newPayment.amountNumber: " + newPayment.amountNumber);
                            runningTotal += newPayment.amountNumber;
                            currencySymbol = newPayment.currencySymbol;

                            console.log("runningTotal: " + runningTotal);
                        }

                        let totalItem = {
                            objectId: "Total",
                            paymentObjectId: "n/a",  
                            paymentOperator: "+",
                            amount: currencySymbol + runningTotal.toFixed(2), 
                            date: "Total",
                            tipperName: "", 
                        }

                        // console.log("totalItem: " + JSON.stringify(totalItem, null, 2));
                        this.paymentsArray.push(totalItem);


                        // this.paymentsArray = this.mostRecentPaymentsNotYetInTransitArray; 

                        // console.log("this.paymentsArray: " + JSON.stringify(this.paymentsArray));

                        for (const newPayment of this.paymentsArray) {
                            // console.log("payout payment id: " + newPayment.paymentObjectId + " date: " + newPayment.date + " clearing date: " + newPayment.clearingDate);
                        } 
                    });


 
                } else {
                    const payments = await Parse.Cloud.run("initPayoutBreakdown", params);  

                    for (const payment of this.paymentsArray) {
                        payment.paymentAmountReceived = "stopped"; // it's a string
                    }
 
                    this.$nextTick(() => { 
                    // the key is we CANNOT update the array before the above for has re-rendered otherwise we get into an endless loop
                        this.paymentsArray = payments; 

                        console.log("this.paymentsArray: " + JSON.stringify(this.paymentsArray));

                        for (const newPayment of this.paymentsArray) {
                            // console.log("payout payment id: " + newPayment.paymentObjectId);
                        }

                        for (const newPayment of this.paymentsArray) {
                            // console.log("payout payment id: " + newPayment.paymentObjectId);
                            console.log("newPayment.amountNumber: " + newPayment.amountNumber);
                            runningTotal += newPayment.amountNumber;
                            currencySymbol = newPayment.currencySymbol;

                            console.log("runningTotal 2: " + runningTotal);
                        }

                        let totalItem = {
                            objectId: "Total",
                            paymentObjectId: "n/a",  
                            paymentOperator: "+",
                            amount: currencySymbol + runningTotal.toFixed(2), 
                            date: "Total",
                            tipperName: "", 
                        }

                        // console.log("totalItem: " + JSON.stringify(totalItem, null, 2));
                        this.paymentsArray.push(totalItem);

                    });
                    
                }


                return;

            } catch (e) {
                // /console.log("BROWSER initTipper FAILED");
                console.error(e);
                throw e;
            } 
        },
    },
    created(){
        Parse.serverURL = 'https://parseapi.back4app.com/'; Parse.initialize(this.appId, this.appJSKey); (this.appId, this.appJSKey);
        this.initpayoutbreakdown();
    },
    mounted(){
        this.doFontSizeComputed();
        this.vhMult = shared.setScreenHeightMultiplier();
        shared.saveToUserPath(this.devEnv, "Payout breakdown " + new Date().toISOString(), this.globalPushForwardInterval);
        // console.log("this.nextScreen: " + this.userData.nextScreen);
    }
}
</script>

<style scoped>


</style>