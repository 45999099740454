<template>
    <!-- See: `getFullVh()` -->
    <div style="overflow: hidden; height: 0">
        <div id="measure-vh" style="position: fixed; height: 100vh"></div>
    </div>
    <!-- showPayouts -->
    <div id="topdiv" class="modal-overlay" style="width: 100%; ">
        <div class="modal" style="outline:0.0em solid blue; display: table-cell; padding-top: 0vh; padding-bottom: 0vh; " :style="{ 'height': 98 * this.vhMult + 'vh'}"> 

        <table width=100% border=0 style="padding-left: 2.5vh; padding-right: 2.5vh; ">
            <tr>
                <td>
                    <table width="100%" border=0 style="height: 6vh">
                        <tr>
                            <td width="30%">
                                <div class=divl><button class="text-only-button cancel" @click="this.goBack()" style="display: block;" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }"><img class="tulogo" valign=middle style="height: 1.8vh; padding-bottom: 0.3vh" alt="back" src="../assets/chevron.png"> Back</button></div>
                            </td>
                            <td width="40%" ><span style="display: block;" :style="{ 'font-size': fontSizeNormalInPXToUse * 1.2 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse *1.2 + 'px', }">Payouts</span>
                            </td>
                            <td width="30%">
                            <div class=divr>
                                &nbsp;
                                <button @click="this.doShowManage()" class="text-only-button cancel divr"  style="display:black" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">Manage</button>
                                <!-- was display:black -->
                            </div>
                            </td>

                        </tr>
                    </table>
                    
                </td>
            </tr>
            <tr v-if="this.payoutsArray.length !== 0">
                <td >
                    <!-- <div style="overflow:auto;" :style="{ 'height': 80 * this.vhMult + 'vh'}"> -->
                        <table class="wrapper-todiv"  align=center cellpadding=0 border=0 width=100%>
            
                            <tr>

                                <td align=left width=40% class="lt-blue divl" style="height: 1vh; letter-spacing: -0.41px" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                                    <span style="color:#718096; padding-right:2vh" >Payment date</span>
                                </td>
                                
                                <td align=center width=30%  :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                                    <span style="color:#718096; padding-right:2vh" >Status</span>
                                </td>
                                <td align=right width=20% class="lt-blue divr" style="height: 1vh; letter-spacing: -0.41px" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                                    <span style="color:#718096; padding-right:0vh" >Amount</span>
                                </td>
                                <td align=right width=10% >
                                    
                                </td>
                                
                            </tr>
                    
                        </table>
                    <!-- </div>               -->
                </td>
            </tr>
            <tr v-if="this.payoutsArray.length !== 0">
                <td >
                    <div style="overflow:auto;" :style="{ 'height': 80 * this.vhMult + 'vh'}">
                    <div :key="payout.objectId" v-for="payout of payoutsArray" >
                        <PayoutRecord :payoutObjectId=payout.objectId :payoutStatus=payout.status  :payoutOperator=payout.payoutOperator :payoutAmountReceived=payout.payoutAmountReceived :payoutDate=payout.date :payoutDateInMillis=payout.dateInMillis  :payoutBasis=payout.payoutBasis @payout-selected="payoutselected"/>
                    </div>   
                    </div>              
                </td>
            </tr>
            <tr v-if="this.payoutsArray.length === 0">
                <td >
                    <div style="overflow:auto;" :style="{ 'height': 80 * this.vhMult + 'vh'}">
                        <br><br><br><br><br>No payouts so far
                    </div>              
                </td>
            </tr>
        </table>       
      </div>
    </div>
</template>

<script>

const Parse = require('parse/node');

import PayoutRecord from './PayoutRecord.vue'
import shared from '../shared.js';

export default { 
    name: 'PayoutsScreen', 
    inject: ['globalPushForwardInterval', 'devEnv', 'appId', 'appJSKey'] ,
    props:  {
        userData: shared.userDataStruct(),
    },
    components: { 
        PayoutRecord,
    },
    data () {
        return {
            fontSizeNormal: 2.5, // vh
            fontSizeNormalInPXToUse: "", 
            fontSizeNormalLineHeight: 3.3,// vh
            fontSizeNormalInPXLineHeightToUse: "",
            fontSizeRecord: 2.5, // vh
            fontSizeRecordInPXToUse: "",
            fontSizeRecordLineHeight: 2.7,// vh
            fontSizeRecordInPXLineHeightToUse: "",

            userObjectId: undefined,
            payoutsArray: [
                {
                objectId: 1,
                payoutAmountReceived: undefined,
              },
              {
                objectId: 2,
                payoutAmountReceived: undefined,
              },
              {
                objectId: 3,
                payoutAmountReceived: undefined,
              },
              {
                objectId: 4,
                payoutAmountReceived: undefined,
              },
              {
                objectId: 5,
                payoutAmountReceived: undefined,
              },
               {
                objectId: 6,
                payoutAmountReceived: undefined,
              },
               {
                objectId: 7,
                payoutAmountReceived: undefined,
              },
               {
                objectId: 8,
                payoutAmountReceived: undefined,
              },
               {
                objectId: 9,
                payoutAmountReceived: undefined,
              },
               {
                objectId: 10,
                payoutAmountReceived: undefined,
              },
               {
                objectId: 11,
                payoutAmountReceived: undefined,
              },
               {
                objectId: 12,
                payoutAmountReceived: undefined,
              },
               {
                objectId: 13,
                payoutAmountReceived: undefined,
              },
               {
                objectId: 14,
                payoutAmountReceived: undefined,
              },
               {
                objectId: 15,
                payoutAmountReceived: undefined,
              },
               {
                objectId: 16,
                payoutAmountReceived: undefined,
              },
               {
                objectId: 17,
                payoutAmountReceived: undefined,
              },
            ],
            vhMult: 1,
            availablePaymentsArray: [],
            mostRecentPaymentsNotYetInTransitArray: [],
            forWeeklyMonthlyAvailableNotYetInTransitArray: [],
        }
    },
    methods: {
        doFontSizeComputed () {
            this.fontSizeNormalInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormal, 16);
            this.fontSizeNormalInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormalLineHeight, 20);

            this.fontSizeRecordInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeRecord, 16);
            this.fontSizeRecordInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeRecordLineHeight, 17);

        },
        payoutselected(payoutObjectId, payoutDate, payoutBasis){
            // console.log("payout object ID selected: " + payoutObjectId);
            // console.log("Payout basis: " + payoutBasis);
            if (payoutBasis === "manual"){
                this.doShowMakePayout();
                return;
                // do nothing can't break down
            } else {
                // console.log("Payout basis: " + payoutBasis);
            }
            this.userData.selectedPayoutId = payoutObjectId;
            this.userData.nextScreen = "showPayoutBreakdown";
            Parse.Cloud.run("sendEmail", {
                toEmail: "appalert@thanku.app", 
                subject: "ALERT! " + this.userData.displayname + " viewed Payout breakdown",
                body: "",
            });
            this.$emit("next-action", this.userData);

            // this.$emit("next-action", "showPayoutBreakdown", undefined, undefined, undefined, this.userId, payoutObjectId);
        },
        goBack(){
            // console.log("now I am on Payouts and I should be: " + this.userData.userObjectId);
            this.userData.nextScreen = "showAccountScreen";
            // console.log("this.userData::: " + JSON.stringify(this.userData, null, 2));
            this.$emit("next-action", this.userData);
            // this.$emit("next-action", "backFromPayouts");
        },
        doShowManage(){
            // console.log("do show manage");
            this.userData.fromScreen = "showPayouts";
            this.userData.nextScreen = "showPayoutsManage";
            Parse.Cloud.run("sendEmail", {
                toEmail: "appalert@thanku.app", 
                subject: "ALERT! " + this.userData.displayname + " viewed Payouts manage",
                body: "",
            });
            this.$emit("next-action", this.userData);
        },
        doShowMakePayout(){
            // console.log("do show make payout");
            this.userData.fromScreen = "showPayouts";
            this.userData.nextScreen = "showMakePayout";

            this.$emit("next-action", this.userData);
        },
        async initpayouts() {
            
            // if (window.localStorage.getItem("tuob")){
            //     this.userObjectId = window.localStorage.getItem("tuob");
            // } 

            // console.log("this surely is my id: " + this.userData.userObjectId);

            var params = {
                userObjectId: this.userData.userObjectId,
            }
            
            try {
               
                const payouts = await Parse.Cloud.run("initPayouts", params);  

                for (const payout of this.payoutsArray) {
                    payout.payoutAmountReceived = "stopped"; // it's a string
                    
                } 

                this.$nextTick(() => { 
                    // the key is we CANNOT update the array before the above for has re-rendered otherwise we get into an endless loop
                    this.payoutsArray = payouts; 

                    for (const payout2 of this.payoutsArray) {
                    
                        // console.log("payout Id: " + payout2.objectId + " millis: " + payout2.dateInMillis);
                        // console.log("payout: " + JSON.stringify(payout2, null, 2));
                    }
 
                    // console.log("this.userData.mostRecentPaymentsNotYetInTransitArray::: " + JSON.stringify(this.userData.mostRecentPaymentsNotYetInTransitArray, null, 2));

                    // console.log("this.userData.pendingNotYetInPayoutsBalance.length:: " + this.userData.pendingNotYetInPayoutsBalance.length);

                    // For a second, I thought THIS SHOULD BE DONE ON THE SERVER, AND it needs to address the balance available which hasn't been paid out, additionally
                    // BUT then I realised we are simply going to be using the this.userData.paymentsArray object which has JUST come from the Dashboard, totally pointless to fetch the same data again

                    // if (false) {
                   
                    this.mostRecentPaymentsNotYetInTransitArray = this.userData.paymentsArray.filter(item => item.mostRecentPaymentsNotYetInTransit === true);
                    this.availablePaymentsArray = this.userData.paymentsArray.filter(item => item.inAvailableBalanceNotYetPaid === true);
                    
                    if (this.userData.stripeBalancesObject.availableNowBalance !== undefined && this.userData.stripeBalancesObject.availableNowBalanceNumber > 0 && (this.userData.recipientPayoutsFrequency === "monthly" || this.userData.recipientPayoutsFrequency === "weekly")){
                        // there will be payments which are in Stripe's 'Total Balance' and 'Future Payouts' which are not in transit because of monthly or weekly payouts, they are being picked up in AvailableBalance. to pick these up we need to filter for pendingPayment is true and most RecentPaymentsNotYetInTransit = false, not sure why the latter but it is the case

                        this.forWeeklyMonthlyAvailableNotYetInTransitArray = this.userData.paymentsArray.filter(item => (item.mostRecentPaymentsNotYetInTransit === false && item.pendingPayment === true));
                    }


                    let filteredPayoutsArray = [];

                    if (this.mostRecentPaymentsNotYetInTransitArray.length > 0) {

                        filteredPayoutsArray = filteredPayoutsArray.concat(this.mostRecentPaymentsNotYetInTransitArray);

                        // console.log("this.payoutsArray::: " + JSON.stringify(this.payoutsArray, null, 2));
                    }

                    if (this.forWeeklyMonthlyAvailableNotYetInTransitArray.length > 0) {

                        filteredPayoutsArray = filteredPayoutsArray.concat(this.forWeeklyMonthlyAvailableNotYetInTransitArray);

                        console.log("this.forWeeklyMonthlyAvailableNotYetInTransitArray::: " + JSON.stringify(this.forWeeklyMonthlyAvailableNotYetInTransitArray, null, 2));
                    }

                    
                    if (this.availablePaymentsArray.length > 0) {

                        filteredPayoutsArray = filteredPayoutsArray.concat(this.availablePaymentsArray);

                        // console.log("this.payoutsArray::: " + JSON.stringify(this.payoutsArray, null, 2));
                    }
                    
                    let recipientPayoutsFrequencyChangedDate = new Date(this.userData.recipientPayoutsFrequencyChangedDate);
                    console.log("recipientPayoutsFrequencyChangedDate day: " + recipientPayoutsFrequencyChangedDate.getDay());
                    let compDate = new Date();
                    compDate.setDate(compDate.getDate() - 45); // see note on RecipientDashboard.vue
                    let hasChangedPayoutFrequencyRecently = false;

                    if (compDate > recipientPayoutsFrequencyChangedDate){
                    } else {
                        hasChangedPayoutFrequencyRecently = true;
                    }

                    let dummyPayoutItem = undefined;

                    if (this.userData.stripeBalancesObject.availableNowBalance !== undefined && this.userData.stripeBalancesObject.availableNowBalanceNumber > 0 && (this.userData.recipientPayoutsFrequency === "manual" )){

                        // console.log("WE HAVE AN AVAILABLE BALANCE")
                        dummyPayoutItem = {
                            objectId: "availableNowBalance",
                            date: "available",
                            dateInMillis: "anyMillis",
                            payoutOperator: "+",
                            status: "available",
                            payoutBasis: "manual",
                            clearingDate: "now",
                            payoutAmountReceived: this.userData.stripeBalancesObject.availableNowBalance,
                        }

                    } else if (this.userData.stripeBalancesObject.availableNowBalance !== undefined && this.userData.stripeBalancesObject.availableNowBalanceNumber > 0 && (this.userData.recipientPayoutsFrequency === "monthly" || this.userData.recipientPayoutsFrequency === "weekly")){

                        //  dummyPayoutItem = {
                        //     objectId: "availableNowBalance",
                        //     date: "available",
                        //     dateInMillis: "anyMillis",
                        //     payoutOperator: "+",
                        //     status: "available",
                        //     payoutBasis: "automatic",
                        //     clearingDate: "now",
                        //     payoutAmountReceived: this.userData.stripeBalancesObject.availableNowBalanceNumber,
                        // }

                        // console.log("this.userData.stripeBalancesObject.availableNowBalance: " + this.userData.stripeBalancesObject.availableNowBalanceNumber);
                        // filteredPayoutsArray.unshift(dummyPayoutItem);
                        // console.log("added to array: " + JSON.stringify(dummyPayoutItem, null, 2));
                        // dummyPayoutItem = undefined;
                    }


                    
                    if (filteredPayoutsArray.length !== 0){
                        console.log("we have length");
                        this.payoutsArray = this.generateDummyPayouts(filteredPayoutsArray);
                    }
 
                    if (dummyPayoutItem !== undefined){
                        console.log("Added Available dummy payout");
                        this.payoutsArray.unshift(dummyPayoutItem);
                    }

                  // OK SO this does work however I will have to assemble a fake payout for the iterated total of any items in this array, then pass the original array forward to the breakdown page
                });
                
            
                
                // console.log("PAYOUTS::: " + JSON.stringify(payouts, null, 2));
                
                return;

            } catch (e) {
                // /console.log("BROWSER initTipper FAILED");
                console.error(e);
                throw e;
            } 
        },
        generateDummyPayouts(filteredPayoutsArray){
            try {
                console.log("well it's more!");

                let totalTipsInDummyPayment = 0;

                let dummyPayoutItem = undefined;
                let dummyPayoutDate = undefined;
                let dummyPayoutCurrency = undefined;

                let tips = filteredPayoutsArray;

                for (var i = tips.length - 1; i >= 0; i--) {

                    console.log("TIP: " + JSON.stringify(tips[i], null, 2));

                    let tipClearingDate;

                    if (tips[i].clearingDate === "now"){
                        if (tips[i].clearingDateForDummyPayout === undefined){
                            tips[i].clearingDateForDummyPayout = new Date();
                            console.log("TIP  2 : " + JSON.stringify(tips[i], null, 2));
                        }
                        tipClearingDate = tips[i].clearingDateForDummyPayout;
                        console.log("tips[i].clearingDateForDummyPayout: " + tips[i].clearingDateForDummyPayout);
                    } else {
                        tipClearingDate = new Date(tips[i].clearingDateInMillis);
                    }

                    let nextPayoutDate;

                    console.log("this.userData.recipientPayoutsFrequency: " + this.userData.recipientPayoutsFrequency);

                    if (this.userData.recipientPayoutsFrequency !== undefined && (this.userData.recipientPayoutsFrequency === "weekly" || this.userData.recipientPayoutsFrequency === "monthly")) {
                        nextPayoutDate = this.getNextPayoutDate(this.userData.recipientPayoutsFrequency, this.userData.recipientPayoutsFrequencyAnchor);

                        console.log("nextPayoutDate: " + nextPayoutDate);
                        console.log("tipClearingDate: " + tipClearingDate);
                        

                        let tipClearingDateDateObject = new Date(tipClearingDate);
                        console.log("tipClearingDate: " + tipClearingDateDateObject);
                        let options = { weekday: undefined, year: 'numeric', month: 'numeric', day: 'numeric' };
                        this.deviceFullObject = shared.getDeviceFullObject();
                        let locale = this.deviceFullObject.locale;

                        console.log("nextPayoutDate > tipClearingDate is " + (nextPayoutDate > tipClearingDateDateObject));

                        if (nextPayoutDate > tipClearingDateDateObject) {
                            tipClearingDate = nextPayoutDate;
                            tipClearingDate = tipClearingDate.toLocaleDateString(locale, options);
                            console.log("nextPayoutDate > tipClearingDate is TRUE");

                        } else {

                            if (this.userData.recipientPayoutsFrequency === "weekly"){                            
                                nextPayoutDate.setDate(nextPayoutDate.getDate() + 7);

                                tipClearingDate = nextPayoutDate;
                            
                                tipClearingDate = tipClearingDate.toLocaleDateString(locale, options);

                            } else if (this.userData.recipientPayoutsFrequency === "monthly"){

                                console.log("Going the extra mile");

                                let nextPayoutMonth = nextPayoutDate.getMonth() + 1;
                                nextPayoutDate = new Date(nextPayoutDate.getFullYear(), nextPayoutMonth, this.userData.recipientPayoutsFrequencyAnchor, 23, 59, 59);

                                tipClearingDate = nextPayoutDate;
                           
                                tipClearingDate = tipClearingDate.toLocaleDateString(locale, options);
                            }       

                        }
                    }



                    if (dummyPayoutDate !== undefined){
                        if (tipClearingDate !== dummyPayoutDate){
                            // console.log("totalTipsInDummyPayment:: " + totalTipsInDummyPayment + " for " + dummyPayoutDate + " payout date");
                            dummyPayoutDate = tipClearingDate;
                            this.payoutsArray = [dummyPayoutItem, ...this.payoutsArray]; // ok that date's done
                            dummyPayoutDate = undefined;
                            dummyPayoutCurrency = undefined;
                            totalTipsInDummyPayment = 0;
                        }
                    }

                    let tipAmount = +tips[i].amount;
                    totalTipsInDummyPayment += tipAmount;
                    

                    if (dummyPayoutDate === undefined){
                        dummyPayoutDate = tipClearingDate;
                    }
                    if (dummyPayoutCurrency === undefined){
                        dummyPayoutCurrency = tips[i].currencySymbol;
                    }

                    tips[i].payoutId = "po_dummy" + dummyPayoutDate;
                    tips[i].tipperName = tips[i].paymentOtherPartyName; // this is just an artefact - the original nomenclature for the Recipient Dashboard is different from what is used on the next breakdown page

                    dummyPayoutItem = {
                        objectId: "po_dummy" + dummyPayoutDate,
                        date: dummyPayoutDate,
                        dateInMillis: "it's a dummy",
                        payoutOperator: "+",
                        status: "pending",
                        payoutAmountReceived: dummyPayoutCurrency + totalTipsInDummyPayment.toFixed(2),
                    }

                    console.log("dummyPayoutItem: " + JSON.stringify(dummyPayoutItem, null, 2));

                }

                // console.log("FINAL totalTipsInDummyPayment:: " + totalTipsInDummyPayment + " for " + dummyPayoutDate + " payout date");

                return [dummyPayoutItem, ...this.payoutsArray]; // always catches the last one

                        
            } catch (e){
                // console.log("ERROR in generateDummyPayouts: " + e.message);
                throw e;
            }
        },
        getNextPayoutDate(recipientPayoutsFrequency, recipientPayoutsFrequencyAnchor){

            let nowDate = new Date();
            let nowDay = nowDate.getDay();
            let nowDayOfMonth = nowDate.getDate();
            let payoutDayNumber;
            let nextPayoutDate = new Date();

            if (recipientPayoutsFrequency === "weekly"){
                if (recipientPayoutsFrequencyAnchor === "monday"){
                    payoutDayNumber = 1;
                } else if (recipientPayoutsFrequencyAnchor === "tuesday"){
                    payoutDayNumber = 2;
                } else if (recipientPayoutsFrequencyAnchor === "wednesday"){
                    payoutDayNumber = 3;
                } else if (recipientPayoutsFrequencyAnchor === "thursday"){
                    payoutDayNumber = 4;
                } else if (recipientPayoutsFrequencyAnchor === "friday"){
                    payoutDayNumber = 5;
                }
                let nowToPayoutDayNumberDiff = payoutDayNumber - nowDay;

                if (nowToPayoutDayNumberDiff < 0) {
                    nowToPayoutDayNumberDiff += 7;
                }

                nextPayoutDate.setDate(nowDate.getDate() + nowToPayoutDayNumberDiff);
                nextPayoutDate.setHours(23,59,59);


            } else if (recipientPayoutsFrequency === "monthly"){

                payoutDayNumber = recipientPayoutsFrequencyAnchor;

                let monthNumToUse;

                if (nowDayOfMonth <= payoutDayNumber){
                    monthNumToUse = nowDate.getMonth();
                } else {
                    monthNumToUse = nowDate.getMonth() + 1;
                }

                nextPayoutDate = new Date(nowDate.getFullYear(), monthNumToUse, recipientPayoutsFrequencyAnchor, 23, 59, 59);

            }

            

            return nextPayoutDate;
        },
        generateDummyPayoutsOLD(filteredPayoutsArray){
            try {
                // console.log("well it's more!");

                let totalTipsInDummyPayment = 0;

                let dummyPayoutItem = undefined;
                let dummyPayoutDate = undefined;
                let dummyPayoutCurrency = undefined;

                let tips = filteredPayoutsArray;

                for (var i = tips.length - 1; i >= 0; i--) {

                    let tipClearingDate;

                     if (tips[i].clearingDate === "now"){
                        tipClearingDate = tips[i].clearingDateForDummyPayout;
                     } else {
                        tipClearingDate = tips[i].clearingDate;
                     }

                    if (dummyPayoutDate !== undefined){
                        if (tipClearingDate !== dummyPayoutDate){
                            // console.log("totalTipsInDummyPayment:: " + totalTipsInDummyPayment + " for " + dummyPayoutDate + " payout date");
                            dummyPayoutDate = tipClearingDate;
                            this.payoutsArray = [dummyPayoutItem, ...this.payoutsArray]; // ok that date's done
                            dummyPayoutDate = undefined;
                            dummyPayoutCurrency = undefined;
                            totalTipsInDummyPayment = 0;
                        }
                    }

                    let tipAmount = +tips[i].amount;
                    totalTipsInDummyPayment += tipAmount;
                    

                    if (dummyPayoutDate === undefined){
                        dummyPayoutDate = tipClearingDate;
                    }
                    if (dummyPayoutCurrency === undefined){
                        dummyPayoutCurrency = tips[i].currencySymbol;
                    }

                    tips[i].payoutId = "po_dummy" + dummyPayoutDate;
                    tips[i].tipperName = tips[i].paymentOtherPartyName; // this is just an artefact - the original nomenclature for the Recipient Dashboard is different from waht is used on the next breakdown page

                    dummyPayoutItem = {
                        objectId: "po_dummy" + dummyPayoutDate,
                        date: dummyPayoutDate,
                        dateInMillis: "it's a dummy",
                        payoutOperator: "+",
                        status: "pending",
                        payoutAmountReceived: dummyPayoutCurrency + totalTipsInDummyPayment.toFixed(2),
                    }

                }

                // console.log("FINAL totalTipsInDummyPayment:: " + totalTipsInDummyPayment + " for " + dummyPayoutDate + " payout date");

                return [dummyPayoutItem, ...this.payoutsArray]; // always catches the last one

                        
            } catch (e){
                // console.log("ERROR in generateDummyPayouts: " + e.message);
                throw e;
            }
        }
    },
    created(){
        // console.log("objectId: " + this.objectId);
        Parse.serverURL = 'https://parseapi.back4app.com/'; Parse.initialize(this.appId, this.appJSKey); (this.appId, this.appJSKey);
        this.initpayouts();
    },
    mounted(){
        this.doFontSizeComputed();
        this.vhMult = shared.setScreenHeightMultiplier();
        shared.saveToUserPath(this.devEnv, "Payouts history " + new Date().toISOString(), this.globalPushForwardInterval);
    }
}
</script>

<style scoped>


</style>